.pagination {
  justify-content: flex-end;
  margin-bottom: 10px;
  .pagebtn {
    color: #fff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
    margin: 0px 4px;
    &:disabled {
      color: #5b5b65;
    }
  }
  .page-info {
    line-height: 30px;
    margin: 0px 10px;
  }
  input {
    background: #262626;
    color: #fff;
    outline: none;
    border: 1px solid;
    margin-left: 10px;
    padding: 0px 0px 0 10px;
    width: 60px;
  }
  select {
    background: #262626;
    color: #fff;
    border: none;
    outline: none;
  }
}
