select {
  color: #bababa;
  -webkit-text-fill-color: #bababa;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='%23bababa'><polygon points='0,0 10,0 5,5'/></svg>")
  no-repeat;
  background-position: 100% 60%;
  background-color: #262626;
  &.form-select {
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #575757;
    padding-left: 2px;
    color: #bababa;
    -webkit-text-fill-color: #bababa;
    font-size: 0.9rem;
    font-weight: 300;
    outline: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='%23bababa'><polygon points='0,0 10,0 5,5'/></svg>")
    no-repeat;
    background-position: 100% 60%;
    // background-color: #262626;
    appearance: none;
    &:focus {
      border-color: #575757;
      outline: 0;
      box-shadow: none;
    }
  }
}

.m,
.p {
  &r,
  &l {
    &-0 {
      margin-right: 0 !important;
    }
    &-1 {
      margin-right: 0.25rem !important;
    }
    &-2 {
      margin-right: 0.5rem !important;
    }
    &-3 {
      margin-right: 1rem !important;
    }
    &-4 {
      margin-right: 1.5rem !important;
    }
    &-5 {
      margin-right: 3rem !important;
    }
  }
}
