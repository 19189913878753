$table-border-color: #ccc;

/* _tables.scss:132 */
$table-dark-color: rgb(241,241,241);
$table-dark-bg: #262626;
$table-dark-border-color: #262626;
$table-dark-accent-bg: #262626;
$table-dark-hover-bg: #262626;

/* _print.scss:123 */
.table-dark {
  color: inherit;

  th,
  td,
  thead th,
  tbody + tbody {
    border-color: $table-border-color;
    background-color: transparent;
  }
}

/* _tables.scss:132 */
.table-dark {
  color: $table-dark-color;
  background-color: $table-dark-bg;

  th,
  td,
  thead th {
    border-color: $table-dark-border-color;
  }

  &.table-bordered {
    border: 0;
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      background-color: $table-dark-accent-bg;
    }
  }

//   &.table-hover {
    // tbody tr {
    //   @include hover {
    //     background-color: $table-dark-hover-bg;
    //   }
    // }
//   }
}
.no-data-widget{
  color: #fff;
  height: 150px;
  div i{
    font-size: 26px;
    margin-bottom: 10px;
  }
  p{
    font-size: 16px;
  }
}