:root {
  --primary-color: #262626;
  --secondary-color: #818cab;
  --font-color: #f1f1f1;
  /* --bg-color: #262626; */
  --bg-color: #1b1d21;

  /* nav-var */
  --nav-color-main: #fff;
  --nav-color: #f1f1f1;

  --spinner-color:#fff;
  --spinner-background:#04040430;
}

[data-theme="light"] {
  --primary-color: #fff;
  --secondary-color: #807373;
  --font-color: #0f0f0f;
  --bg-color: #fff;

  /* nav-var */
  --nav-color-main: #030303;
  --nav-color: #111111;

  --spinner-color:#262626;
  --spinner-background:#e2e0e08e;

}

.nav-item a.nav-link {
  cursor: pointer;
}
.modal-content {
  background-color: var( --primary-color) !important;
}
.modal-content .btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
}
.event-update-details {
  padding-left: 30px;
  position: relative;
}
.event-update-details span.colon {
  position: absolute;
  left: 0;
}
header .nav-item .nav-link {
  border-bottom: 1px solid transparent;
}
header .nav-item .nav-link.active {
  border-bottom: 1px solid var(--nav-color-main);
}

ul.table-ul-list {
  padding: 0;
  margin: 0;
}

.float-element {
  position: fixed;
  width: 98%;
  left: 1%;
  border: none;
  /* left: 50%;
  transform: translateX(-50%) */
  padding: 10px 60px;
  /* width: 90%; */
  box-shadow: 0 8px 16px 0 rgb(68 67 67 / 22%), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: linear-gradient(45deg, #242323fa, #222222f5);
  border-radius: 4px;
  text-align: center;
  transition: bottom 1s;
}
.floating-widget {
  display: inline-block;
}
.floating-widget button {
  padding: 5px 28px;
  /* background: red; */
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
}

.event-update-details span.icon {
  position: absolute;
  left: 11px;
  color: #aeaeae;
  font-size: 15px;
  line-height: 26px;
  cursor: pointer;
}
/* Fixme  */
select.form-control {
  background: #262626;
  color: #fff;
}
select.form-control option {
  background: #262626;
  color: #fff;
}
.preloader-area.relative-container {
  position: absolute;
  transform: scale(0.6);
}
.preloader-area {
  position: fixed;
  top: 0;
  background-color: var(--spinner-background);
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
}
.preloader-area .spinner {
  width: 4em;
  height: 4em;
  -webkit-transform: perspective(20em) rotateX(-24deg) rotateY(20deg)
    rotateZ(30deg);
  transform: perspective(20em) rotateX(-24deg) rotateY(20deg) rotateZ(30deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%);
  margin-left: auto;
  margin-right: auto;
}
.preloader-area .spinner .disc {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 0.3em dotted var(--spinner-color);
}
.preloader-area .spinner .disc:nth-child(1) {
  -webkit-animation: rotate 12s linear infinite;
  animation: rotate 12s linear infinite;
}
.preloader-area .spinner .disc:nth-child(2) {
  -webkit-animation: rotateDisc2 12s linear infinite;
  animation: rotateDisc2 12s linear infinite;
}
.preloader-area .spinner .disc:nth-child(3) {
  -webkit-animation: rotateDisc3 12s linear infinite;
  animation: rotateDisc3 12s linear infinite;
}
.preloader-area .spinner .inner {
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-animation: sphereSpin 6s linear infinite;
  animation: sphereSpin 6s linear infinite;
}
.preloader-area .spinner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  border: 2px dotted var(--spinner-color);
  margin: -15px;
}
@-webkit-keyframes sphereSpin {
  0% {
    -webkit-transform: rotateX(360deg) rotateY(0deg);
    transform: rotateX(360deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateX(0deg) rotateY(360deg);
    transform: rotateX(0deg) rotateY(360deg);
  }
}
@keyframes sphereSpin {
  0% {
    -webkit-transform: rotateX(360deg) rotateY(0deg);
    transform: rotateX(360deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateX(0deg) rotateY(360deg);
    transform: rotateX(0deg) rotateY(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateDisc2 {
  from {
    -webkit-transform: rotateX(90deg) rotateZ(0deg);
    transform: rotateX(90deg) rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateX(90deg) rotateZ(360deg);
    transform: rotateX(90deg) rotateZ(360deg);
  }
}
@keyframes rotateDisc2 {
  from {
    -webkit-transform: rotateX(90deg) rotateZ(0deg);
    transform: rotateX(90deg) rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateX(90deg) rotateZ(360deg);
    transform: rotateX(90deg) rotateZ(360deg);
  }
}
@-webkit-keyframes rotateDisc3 {
  from {
    -webkit-transform: rotateY(90deg) rotateZ(0deg);
    transform: rotateY(90deg) rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateY(90deg) rotateZ(360deg);
    transform: rotateY(90deg) rotateZ(360deg);
  }
}
@keyframes rotateDisc3 {
  from {
    -webkit-transform: rotateY(90deg) rotateZ(0deg);
    transform: rotateY(90deg) rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateY(90deg) rotateZ(360deg);
    transform: rotateY(90deg) rotateZ(360deg);
  }
}

.table-widget{
  background-color: var(--cardbox-innercardbg);
}
