:root {
  --primary-color: #262626;
  --secondary-color: #818cab;
  --font-color: #f1f1f1;
  --primary-font-color:#fff;
  --pagination-color : #878a99;

  --secondary-font-color:#bababa;
  --fontcolor-three:#aeaeae;
  /* --bg-color: #262626; */
  --nav-bg: #2b2e34;

  --bg-color: #1b1d21;

  /* nav-var */
  --nav-color-main:#fff;
  --nav-color: #f1f1f1;

  /* widgets var */

  --btndone-bgcolor:rgb(34, 104, 34);
  --btndone-hovercolor:#2db64f;

  --btn-upload-bg:#262626;
  --btn-upload-color:#8d8c8c;
  --btn-upload-hover:#262626;
  

  --cardbox-innercardbg:#212529;
  --cardbox-bgcolor:#2c3038;
  --cardhead-color:#fff;
  --cardinner-color:rgb(203 213 225);


  --table-bg:#1b1d21;
  --table-color:#f1f1f1;

  --pagination-border : #404040;
  --bs-modal-bg:#851e1e;


}

[data-theme="light"] {
  --primary-color: #fff;
  --secondary-color: #807373;
  --font-color: #0f0f0f;
  --primary-font-color:#020202;
  --secondary-font-color:#6b6b6b;
  --fontcolor-three:#292828;
  --bg-color: #f3f3f9;
  --cardbox-innercardbg: #fff;
  

  /* nav-var */
  --nav-color-main:#030303;
  --nav-color: #111111;
  --pagination-border : #cacaca;
  --nav-bg: #ebebeb;

  /* widgets var */

  --btndone-bgcolor:#2db64f;
  --btndone-hovercolor:rgb(34, 104, 34);
  
  --btn-upload-bg:#0f0f0fd3;
  --btn-upload-color:#e0dfdf;
  --btn-upload-hover:#262626;

  --cardbox-bgcolor:#0000000c;
  /* --cardbox-innercardbg:#00000042; */
  --cardhead-color:#fff;
  --cardinner-color:rgb(25, 25, 26);

  
  --table-bg:#ffffff;
  --table-color:#020202;




}

body {
  background-color: var(--bg-color);
  color: var(--font-color);
}

.table-url{
  color: var(--table-color);
  text-decoration: none;
}
.table-url:hover{
  color:#bdbdbd;
}
.navbar-nav .nav-item a {
  text-transform: uppercase;
  color: var(--nav-color-main);
}
/* .nav-round {
  background: #303030;
  padding: 0px 45px;
  border-radius: 50px;
  position: relative;
  top: 15px;
} */
.nav-round li {
  margin-right: 5px;
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link {
  color: var(--nav-color-main);
  font-size: 0.95rem;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--nav-color);
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
}
.nav-tabs .nav-link {
  border: none;
  outline: none;
  color: var(--nav-color);
  font-size: 0.95rem;
  padding-left: 5px;
  padding-right: 1.25rem;
  margin-right: 0.5rem;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  color: var(--nav-color);
  border-bottom: 2px solid var(--nav-color);
  /* font-size: 1rem; */
  text-align: center;
}
.nav-item i {
  background: rgb(158, 134, 134);
  padding: 4px;
  color: #303030;
  border-radius: 50px;
  margin-right: 4px;
  margin-left: 2px;
  font-size: 0.75rem;
}
.nav-tabs {
  border-bottom: 1px solid #575757;
  padding-bottom: 1px;
}
.tab-pane {
  padding-top: 2rem;
}
.form-control {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #575757;
  padding-left: 2px;
  color: var(--secondary-font-color);
  font-size: 0.9rem;
  font-weight: 300;
}
.form-control:focus {
  box-shadow: none;
  outline: none;
}
.form-label {
  margin-bottom: 0;
  color: var(--fontcolor-three);
  font-size: 0.95rem;
}
.btn-secondary {
  background-color: #979392;
  margin-right: 10px;
}
.tab-pane h5 {
  font-size: 1.1rem;
}
.input-group-text {
  background-color: transparent;
  color: var(--primary-font-color);
  border: none;
  border-bottom: 2px solid #575757;
  border-radius: 0;
}
.form-control:focus {
  background-color: transparent !important;
  border-color: #646464;
  color: var(--primary-font-color);
}
.table {
  background-color: var(--table-bg);
  color: var(--table-color);
}
.page-item.disabled .page-link {
  background: transparent;
  border: none;
}
.page-item.active .page-link {
  background-color: transparent;
  border: none;
}
.table th {
  font-weight: 500;
  font-size: 0.9rem;
}
.table td {
  font-size: 0.9rem;
}
.page-link {
  background-color: transparent;
  border: none;
  color: #646464;
}
.page-link:hover,
.page-link:focus {
  background-color: transparent;
  color: #f1f1f1;
  box-shadow: none;
}

.sorting::before,
.sorting::after {
  position: absolute;
  bottom: 0.5em;
  display: block;
  top: 6px;
  font-size: 1.2rem;
  opacity: 0.3;
}
.form-control::placeholder {
  color: rgb(145, 145, 145);
  font-size: 0.95rem;
}
.sorting {
  position: relative;
  cursor: pointer;
}
.sorting::before {
  right: 0.5em;
  content: "↑";
}
.sorting::after {
  right: 0.5em;
  content: "↓";
}
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:after {
  opacity: 1;
}
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:after {
  opacity: 1;
}
.dataTables_filter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.dataTables_filter label {
  width: 320px;
  display: flex;
  align-items: flex-end;
}
.dataTables_length label {
  display: flex;
  width: 160px;
}
.dataTables_length .form-select {
  margin: 0 5px;
}
.dataTables_length input {
  position: relative;
  margin-bottom: 4px;
  margin-left: 10px;
}
/* .urlTd{
    display: flex;
} */
.urlTd input {
  margin-right: 10px;
  border-radius: 0 !important;
}
.table td {
  font-size: 0.85rem;
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.5rem;
}
.form-check-input:focus {
  box-shadow: none;
}
.form-check-input {
  cursor: pointer;
}

.action-td.sorting::before,
.action-td.sorting::after {
  display: none !important;
}
a .fa-check-circle {
  color: #00b172;
  font-size: 1.15rem;
  margin: 0 2px;
}
a .fa-times-circle {
  color: #eb4746;
  font-size: 1.15rem;
  margin: 0 2px;
}
.ui-slider {
  position: relative;
  text-align: left;
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 11px;
  height: 11px;
  cursor: pointer;
  -ms-touch-action: none;
  touch-action: none;
  border-radius: 50%;
}
.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: 0.7em;
  display: block;
  border: 0;
  background-position: 0 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit;
}

.ui-slider-horizontal {
  height: 2px;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -0.3em;
  margin-left: -0.6em;
}
.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}
.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: 0.8em;
  height: 100px;
}
.ui-slider-vertical .ui-slider-handle {
  left: -0.3em;
  margin-left: 0;
  margin-bottom: -0.6em;
}
/* Component containers
----------------------------------*/
.ui-widget {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}
.ui-widget .ui-widget {
  font-size: 1em;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}
.ui-widget.ui-widget-content {
  border: 1px solid #c5c5c5;
}
.ui-widget-content {
  border: none !important;
  background: #575757;
  color: #333333;
}
.ui-widget-content a {
  color: #333333;
}
.ui-widget-header {
  border: 1px solid #dddddd;
  background: #e9e9e9;
  color: #333333;
  font-weight: bold;
}
.ui-widget-header a {
  color: #333333;
}

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: 1px solid #c5c5c5;
  background: #f6f6f6;
  font-weight: normal;
  color: #454545;
}
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited,
a.ui-button,
a:link.ui-button,
a:visited.ui-button,
.ui-button {
  color: #454545;
  text-decoration: none;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  border: 1px solid #cccccc;
  background: #ededed;
  font-weight: normal;
  color: #2b2b2b;
}
.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited,
a.ui-button:hover,
a.ui-button:focus {
  color: #2b2b2b;
  text-decoration: none;
}

.ui-visual-focus {
  box-shadow: 0 0 3px 1px rgb(94, 158, 214);
}
.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
}

.form-select {
  padding-right: 0 !important;
  background-color: transparent;
}
label.fileUploadLabel {
  cursor: pointer;
  margin-right: 10px;
}

#file-chosen {
  margin-left: 0.3rem;
  font-family: sans-serif;
}

/* CardSTyles */
.border-card {
  /* border: 1px solid #fff; */
  box-shadow: 0 4px 8px 0 rgba(44, 44, 44, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* background-color: rgba(26, 25, 25, 0.459); */
  background-color: var(--cardbox-innercardbg);
  border: none;
  border-radius: 8px;
  padding-left: 1rem;
  padding-right: 1rem;

  padding-top: 2rem;
  padding-bottom: 1rem;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
}
.border-card:hover {
  /* border: 1px solid #fff; */
  background-color: #212529e4;
  /* padding: 0.5rem; */
  cursor: pointer;
  height: 100%;
  /* display: block; */
  color: #fff;
  text-decoration: none;
}
.border-card h5 {
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
  width: 75%;
  margin: 0;
  padding: 0;
  float: left;
}
.border-card h5.counts {
  text-align: right;
  float: left;
  font-size: 1.9rem;
  margin: 0;
  margin-top: 3px;
  width: 75%;
  /* margin-bottom: 1.4rem; */
  font-weight: 500;
  padding: 0;
}

.border-card i {
  font-size: 1rem;
  width: 35px;
  height: 35px;
  background: #7c7c7c40;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 2.1rem;
  border-radius: 50px;
  line-height: 35px;
  vertical-align: middle;
  text-align: center;
  color: white;
  /* margin-left: 5%;s
}
.data-count {
  /* border: 1px solid #5a5959; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* background-color: rgba(7, 7, 7, 0.027); */
  /* border-radius: 8px; */
  padding: 0rem;
  margin-bottom: 15px;
  /* line-height: 22px; */
}
.data-count h4 {
  font-size: 1.125rem;
  text-transform: uppercase;
  font-weight: 600;
}

.data-count .col-md-4 {
  /* margin-top: 15px; */
  margin-bottom: 15px;
}
.data-count-time {
  text-align: right;
  font-weight: 300;
  font-size: 0.95rem;
}
.border-card h5 span {
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
}
.card-a {
  /* background-image: linear-gradient(#ff45459a, #ff45459c); */
  background-color: var(--cardbox-bgcolor);
  color: var(--cardinner-color);
  border-radius: 8px;
  padding: 15px;
  padding-top: 23px;
}
.card-b {
  /* background-color: white; */
  color: black;
}
.dasboard-card {
  border-radius: 15px;
  /* margin-right: 10px; */
  position: relative;
}
.card-c {
  /* background-image: linear-gradient(#00c5c85d, #00c5c883); */
  /* background-image: linear-gradient(#ff45459a, #ff45459c); */
  background-color: rgb(29 31 34 / 72%);

  color: white;
}
.dashboard-icon {
  width: 50px;
  height: auto;
}
.btn-done {
  padding: 0.2rem 2rem;
  border-radius: 2px;
  border: none;
  /* background: #456464; */
  background: var(--btndone-bgcolor);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.btn-done:hover {
  background: var(--btndone-hovercolor);
}
.btn-dtwo {
  padding: 0.2rem 2rem;
  border-radius: 2px;
  border: none;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.btn-dsmall {
  padding: 0.2rem 0.85rem;
  border-radius: 2px;
  border: none;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.btn-edit {
  padding: 4px 9px;
  background: #7c2121;
  border: none;
  font-size: 0.95rem;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.btn-upload {
  padding: 0.2rem 2rem;
  border-radius: 2px;
  border: none;
  background: var(--btn-upload-bg);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: var(--btn-upload-color);
}
.btn-upload:hover {
  background: var(--btn-upload-hover);
  color: white;
}
.btn-icon-round {
  background: none;
  border-radius: 60px;
  padding: 0px;
  border: none;
}
.btn-icon-round i {
  font-size: 0.9rem;
  width: 25px;
  height: 15px;
}

.table-widget {
  padding: 20px 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 30px;
}
.table-action-btn {
  padding: 4px 15px;
  font-size: 0.8rem;
  background: rgba(255, 255, 255, 0.918);
  border: none;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
}

/* toggle style */
.toggle {
  display: flex;
  margin-right: 20px;
  top: 3px;
  position: relative;
}
.toggle-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.toggle-slot {
  position: relative;
  cursor: pointer;
  height: 2.3em;
  width: 4.5em;
  border: 5px solid transparent;
  border-radius: 10em;
  background-color: white;
  box-shadow: 0px 0px 9px #726a6acf;
  transition: background-color 250ms;
}

.toggle-checkbox:checked ~ .toggle-slot {
  background-color: #374151;
}

.toggle-button {
  transform: translate(2.2em, 0.06em);
  position: absolute;
  cursor: pointer;
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  background-color: #ffeccf;
  box-shadow: inset 0px 0px 0px 0.75em #ffbb52;
  transition: background-color 250ms, border-color 250ms,
    transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .toggle-button {
  background-color: #485367;
  box-shadow: inset 0px 0px 0px 0.75em white;
  transform: translate(0.25em, 0.06em);
}

.sun-icon {
  position: absolute;
  height: 1.5em;
  width: 1.5em;
  color: #ffbb52;
}

.sun-icon-wrapper {
  position: absolute;
  height: 1.5em;
  width: 1.5em;
  opacity: 1;
  transform: translate(0.25em, 0.06em) rotate(15deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .sun-icon-wrapper {
  opacity: 0;
  transform: translate(1.75em, 0.06em) rotate(0deg);
}

.moon-icon {
  position: absolute;
  height: 1.5em;
  width: 1.5em;
  color: white;
}

.moon-icon-wrapper {
  position: absolute;
  height: 1.5em;
  width: 1.5em;
  opacity: 0;
  transform: translate(1.5em, 0.06em) rotate(0deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2.5, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .moon-icon-wrapper {
  opacity: 1;
  transform: translate(2.2em, 0.06em) rotate(-15deg);
}
/* toggle style end */
.news-articleTab #news-articles-table tr td:nth-child(4),
.news-articleTab #news-articles-table tr td:nth-child(5){
  width: 12%;
}
.news-articleTab #news-articles-table tr td:nth-child(6){
  width: 6%;
}
.news-articleTab #news-articles-table tr td span{
  max-width: 100% !important;
}
.pagination .pagebtn{
 color: var(--pagination-color) !important;
 font-weight: 300;
 font-size: 0.95rem;
}
.pagination input, .pagination select{
  background: var( --primary-color) !important;
  border: 1px solid var(--secondary-color) !important;
  color: var(--font-color) !important;
  -webkit-text-fill-color: #bababa !important;
}
#top-domain-table tr td:last-child span{
  display: flex;
  flex-direction: row;  
  height: 100%;
}
.swal2-icon.swal2-info{
  border-color: #b7313d !important;
    color: #d63543 !important;
}
.swal2-popup{
  background: var( --primary-color) !important;
}
.swal2-title,.swal2-html-container{
  color: var(--font-color) !important;
}

.swal2-styled.swal2-confirm{
  background-color: #c3313e !important;
}
.swal2-close:focus{
  box-shadow: none !important;
}
.pagination{
  border-bottom: 1px solid var(-pagination-border );
}
.navbar{
  background-color: var(--nav-bg);
}